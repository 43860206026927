// import Vue from "vue";
//import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Projekte from "../views/ProjectsSite.vue";
import About from "../views/About.vue";
import Imprint from "../views/Imprint.vue";
import PopUp from "../components/PopUp.vue";
import { createRouter, createWebHistory } from 'vue-router';
// import {Capitalize} from "../functions/capitalize"
// Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/projects",
    name: "Projekte",
    component: Projekte
  },
  {
    path: "/projects/:projName",
    name: "PopUp",
    component: PopUp
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: Imprint
  }
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition){
    return savedPosition || {top:0}
  },
});

/**router.beforeEach((to, from, next) =>{
switch (to.name) {
  case "PopUp":

 {const routerParams = to.params.projName;
  const routerParamsArr = routerParams.split("-");

  document.title = `${Capitalize(to.name)(routerParamsArr.slice(0, routerParamsArr.length - 1).join(" "))  } - Jouns`
};
    break;

  default:
    document.title = `${Capitalize(to.name)} - Jouns`;
    break;
}
next();
})**/

export default router;
