import { createApp } from 'vue'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import titleMixin from './mixins/titleMixin';

// Vue.mixin(titleMixin)
// createApp(App).mount('#app')

// Vue.config.productionTip = false;

createApp(App)
.use(store)
.use (router)
.mixin(titleMixin)
.mount('#app')





/**new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  }
}).$mount("#app");**/
